import Reveal from "../shared/Reveal";

const people = [

		{
			"title": "HOUDINI",
			"artist": "EMINEM",
			"image": "https://resources.tidal.com/images/fb1a09e1/7474/4341/867c/50c0eac117c4/320x320.jpg",
			"week": 1,
			"lastWeek": 1
		},
		{
			"title": "LUNCH",
			"artist": "BILLIE EILISH",
			"image": "https://resources.tidal.com/images/6107a068/f4fe/4db5/b741/948d5e3ecedb/320x320.jpg",
			"week": 2,
			"lastWeek": 6
		},
		{
			"title": "ESPRESSO",
			"artist": "SABRINA CARPENTER",
			"image": "https://resources.tidal.com/images/e4f2d75f/a69e/4b8a/b800/e18546b1ad4c/320x320.jpg",
			"week": 3,
			"lastWeek": 5
		},
		{
			"title": "BEAUTIFUL THINGS",
			"artist": "BENSON BOONE",
			"image": "https://resources.tidal.com/images/29f601da/eedf/4652/985a/b0b043e2d03a/320x320.jpg",
			"week": 4,
			"lastWeek": 11
		},
		{
			"title": "FORTNIGHT (FEAT. POST MALONE)",
			"artist": "TAYLOR SWIFT",
			"image": "https://resources.tidal.com/images/dc422d4b/3daa/468b/b2de/3bbfa720a1d7/320x320.jpg",
			"week": 5,
			"lastWeek": 9
		},
		{
			"title": "I DON'T WANNA WAIT",
			"artist": "DAVID GUETTA, ONEREPUBLIC",
			"image": "https://resources.tidal.com/images/e4bf8b6b/0296/4cb0/b3bd/1ccc14c40223/320x320.jpg",
			"week": 6,
			"lastWeek": 18
		},
		{
			"title": "STICK SEASON",
			"artist": "NOAH KAHAN",
			"image": "https://resources.tidal.com/images/c6ba230d/80c3/46d7/88a1/879267b219e2/320x320.jpg",
			"week": 7,
			"lastWeek": 13
		},
		{
			"title": "I LIKE THE WAY YOU KISS ME",
			"artist": "ARTEMAS",
			"image": "https://resources.tidal.com/images/39677d06/ea4b/4c36/9acd/1abfcfc5380e/320x320.jpg",
			"week": 8,
			"lastWeek": 20
		},
		{
			"title": "I HAD SOME HELP (FEAT. MORGAN WALLEN)",
			"artist": "POST MALONE",
			"image": "https://resources.tidal.com/images/0953b83f/1432/4698/84f9/55e1774a778b/320x320.jpg",
			"week": 9,
			"lastWeek": 8
		},
		{
			"title": "THE DOOR",
			"artist": "TEDDY SWIMS",
			"image": "https://resources.tidal.com/images/a67fe208/91da/431e/afdb/016f5875fac1/320x320.jpg",
			"week": 11,
			"lastWeek": 6
		},
		{
			"title": "STARGAZING",
			"artist": "MYLES SMITH",
			"image": "https://resources.tidal.com/images/98d4c025/4920/494c/b081/70c61708edd0/320x320.jpg",
			"week": 12,
			"lastWeek": 8
		},
		{
			"title": "FORGET ABOUT US",
			"artist": "PERRIE",
			"image": "https://resources.tidal.com/images/093da204/4d65/49de/a808/f155500ed189/320x320.jpg",
			"week": 13,
			"lastWeek": 14
		},
		{
			"title": "BAND4BAND",
			"artist": "CENTRAL CEE, LIL BABY",
			"image": "https://resources.tidal.com/images/53ba10bc/851a/459f/a7d5/51a11bbe74b6/320x320.jpg",
			"week": 14,
			"lastWeek": 15
		},
		{
			"title": "NOT MY FAULT",
			"artist": "RENE\u00c9 RAPP & MEGAN THEE STALLION",
			"image": "https://resources.tidal.com/images/5045b838/2981/4f4a/8184/e2f3350daaab/320x320.jpg",
			"week": 15,
			"lastWeek": 16
		},
		{
			"title": "RANSOM",
			"artist": "ALESHA DIXON",
			"image": "https://resources.tidal.com/images/ffe22ed5/2433/42d5/ba78/fd5bf6668560/320x320.jpg",
			"week": 16,
			"lastWeek": 21
		},
		{
			"title": "ALIBI (FEAT. RUDIMENTAL)",
			"artist": "ELLA HENDERSON",
			"image": "https://resources.tidal.com/images/d214b178/82fa/41e9/9abf/46d3ff948844/320x320.jpg",
			"week": 17,
			"lastWeek": 24
		},
		{
			"title": "AGORA HILLS",
			"artist": "DOJA CAT",
			"image": "https://resources.tidal.com/images/ba9fa73e/9335/4014/baee/bf073f22e0f3/320x320.jpg",
			"week": 18,
			"lastWeek": 27
		},
		{
			"title": "LOVIN ON ME",
			"artist": "JACK HARLOW",
			"image": "https://resources.tidal.com/images/7e808de6/10f4/4ff5/aa9e/c06167d93469/320x320.jpg",
			"week": 19,
			"lastWeek": 30
		},
		{
			"title": "FOR LIFE (FEAT. ZAK ABEL & NILE RODGERS)",
			"artist": "KYGO",
			"image": "https://resources.tidal.com/images/88f7781c/4ece/4108/9b4b/f229c9999d13/320x320.jpg",
			"week": 20,
			"lastWeek": 39
		},
		{
			"title": "SATURN",
			"artist": "SZA",
			"image": "https://resources.tidal.com/images/3735b9c9/def9/49f7/ad08/adf1b4df0ce0/320x320.jpg",
			"week": 21,
			"lastWeek": 40
		},
		{
			"title": "MADE FOR ME",
			"artist": "MUNI LONG",
			"image": "https://resources.tidal.com/images/5eee4a4d/acb1/4180/85a0/68cdfe68830b/320x320.jpg",
			"week": 22,
			"lastWeek": 28
		},
		{
			"title": "TOO SWEET",
			"artist": "HOZIER",
			"image": "https://resources.tidal.com/images/0fa466cf/cd36/42b8/abe2/96bb3bd304ea/320x320.jpg",
			"week": 23,
			"lastWeek": 4
		},
		{
			"title": "MAKE YOU MINE",
			"artist": "MADISON BEER",
			"image": "https://resources.tidal.com/images/9eeb3bec/dcf1/4208/8705/6082e21c5bc0/320x320.jpg",
			"week": 24,
			"lastWeek": 22
		},
		{
			"title": "LOSE CONTROL",
			"artist": "TEDDY SWIMS",
			"image": "https://resources.tidal.com/images/283b3a67/289e/423f/87e5/3f834ba10fe6/320x320.jpg",
			"week": 25,
			"lastWeek": 4
		},
		{
			"title": "ILLUSION",
			"artist": "DUA LIPA",
			"image": "https://resources.tidal.com/images/5a97aed9/c073/48de/858d/582da99a14cd/320x320.jpg",
			"week": 27,
			"lastWeek": 32
		},
		{
			"title": "GREEDY",
			"artist": "TATE MCRAE",
			"image": "https://resources.tidal.com/images/582c4ae7/5864/4f9b/97af/740f1e574b99/320x320.jpg",
			"week": 28,
			"lastWeek": 33
		},
		{
			"title": "END OF BEGINNING",
			"artist": "DJO",
			"image": "https://resources.tidal.com/images/5a783782/d47f/4de7/b241/0d2867c12e5a/320x320.jpg",
			"week": 29,
			"lastWeek": 34
		},
		{
			"title": "STUMBLIN' IN",
			"artist": "CYRIL",
			"image": "https://resources.tidal.com/images/11e5de9a/f121/4fd1/8135/0e898c4f40f6/320x320.jpg",
			"week": 30,
			"lastWeek": 12
		},
		{
			"title": "TEXAS HOLD 'EM",
			"artist": "BEYONC\u00c9",
			"image": "https://resources.tidal.com/images/9f17c355/a3e6/4774/8095/1590f2b8afec/320x320.jpg",
			"week": 31,
			"lastWeek": 15
		},
		{
			"title": "ON MY MAMA",
			"artist": "VICTORIA MON\u00c9T",
			"image": "https://resources.tidal.com/images/237692fa/f378/4e66/8f5d/3ca6afc0b588/320x320.jpg",
			"week": 32,
			"lastWeek": 31
		},
		{
			"title": "WE CAN'T BE FRIENDS (WAIT FOR YOUR LOVE)",
			"artist": "ARIANA GRANDE",
			"image": "https://resources.tidal.com/images/e6633aea/bd2e/4a48/80c6/2088193d7cf1/320x320.jpg",
			"week": 33,
			"lastWeek": 38
		},
		{
			"title": "MILLION DOLLAR BABY",
			"artist": "TOMMY RICHMAN",
			"image": "https://resources.tidal.com/images/220b479a/ea12/4241/8fbf/5a288981b789/320x320.jpg",
			"week": 34,
			"lastWeek": 37
		},
		{
			"title": "A BAR SONG (TIPSY)",
			"artist": "SHABOOZEY",
			"image": "https://resources.tidal.com/images/d05b8da3/4fae/45ff/ac1b/7ab7caab3523/320x320.jpg",
			"week": 35,
			"lastWeek": 36
		},
		{
			"title": "PAINT THE TOWN RED",
			"artist": "DOJA CAT",
			"image": "https://resources.tidal.com/images/02865df6/daec/4d46/88a2/435db5dfee9a/320x320.jpg",
			"week": 36,
			"lastWeek": 31
		},
		{
			"title": "MILES ON IT",
			"artist": "MARSHMELLO, KANE BROWN",
			"image": "https://resources.tidal.com/images/4a48765f/5d8f/4d96/8ee5/dbea803e0cf7/320x320.jpg",
			"week": 37,
			"lastWeek": 21
		},
		{
			"title": "WATER",
			"artist": "TYLA",
			"image": "https://resources.tidal.com/images/3cfc7cbd/1405/4395/a124/38723f459d37/320x320.jpg",
			"week": 38,
			"lastWeek": 37
		},
		{
			"title": "AUSTIN (BOOTS STOP WORKIN')",
			"artist": "DASHA",
			"image": "https://resources.tidal.com/images/17153a13/2e4d/4609/8114/70023be04990/320x320.jpg",
			"week": 39,
			"lastWeek": 27
		},
		{
			"title": "PRADA",
			"artist": "RAYE, CASS\u00d6, D-BLOCK EUROPE",
			"image": "https://resources.tidal.com/images/efc4d89c/d1d5/4ec7/ab24/14844bda859d/320x320.jpg",
			"week": 40,
			"lastWeek": 23
		}

  ];

export default function Chart() {
  return (
    <div className=" w-full mt-3 relative">
      <div className="flex flex-col items-start h-full  shadow-md rounded-md overflow-hidden w-full">
        <div className="bg-[#2b0b3d] border-b-2 px-4 py-3 w-full">
          <div className="flex justify-between items-center uppercase ">
            <div className="flex space-x-3">
              <p className="text-sm font-semibold text-white">Week</p>
              <p className="text-sm font-semibold text-white">Name</p>
            </div>
            <div>
              <p className="text-sm font-semibold text-white">Last Week</p>
            </div>
          </div>
        </div>

        <ul className="divide-y divide-gray-700 overflow-y-auto h-80 sm:h-full max-h-[518px] w-full overflow-x-hidden scroll-design">
          {people.map((person, index) => (
            <Reveal key={index}
              hidden={{
                opacity: 0,
                x: index % 2 === 0 ? -75 : 75,
              }}
              visible={{ opacity: 1, x: 0 }}>
              <li
                key={index}
                className={`flex justify-between p-3 hover:bg-purple-200 ${index % 2 === 0 ? "bg-white" : "bg-white"}`}
              >
                <div className="flex items-center gap-3">
                  <p className="text-sm p-2 m-1 font-medium text-white bg-theme-third rounded-full h-[40px] w-[40px] flex items-center justify-center">
                    {String(person.week).padStart(2, "0")}
                  </p>
                  <img
                    className="h-10 w-10 m-1 rounded-full"
                    src={person.image}
                    alt=""
                  />
                  <div className="flex flex-col items-start">
                    <p className="text-sm font-medium text-gray-800">
                      {person.title}
                    </p>
                    <p className="text-sm text-gray-600">{person.artist}</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <p className="text-sm font-medium text-gray-500 ml-2">
                    {person.lastWeek}
                  </p>
                </div>
              </li>
            </Reveal>
          ))}
        </ul>
      </div>
    </div >

  );
}
