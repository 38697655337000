import classNames from "classnames";
import { useEffect } from "react";
import Logo from "../components/icons/Logo";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

export default function Success() {
  const { updateDocument, user } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    updateDocument(user, { paid: true });
  }, [updateDocument, user]);

  return (
    <>
      <Navbar isHome={false} />
      <div className="flex flex-col gap-10  justify-center items-center w-full h-full bg-gradient-to-r from-black to-gray-900">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm flex items-center flex-col">
          <Logo />
        </div>
        <h2 className="text-4xl font-semibold mb-20 text-white text-center">
          Payment Received Successfully
        </h2>
        <button
          className={classNames(
            "flex  justify-center rounded-md w-fit bg-theme-secondary hover:bg-theme-secondary-light transition-colors duration-150 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-secondary"
          )}
          onClick={() => navigate("/")}
        >
          Home Page
        </button>
      </div>
    </>
  );
}
