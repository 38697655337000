import React, { useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import { auth } from "../utils/firebase";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { backendURL } from "../utils/consts";

const checkMarkSvg = (
  <svg
    className="h-6 w-5 flex-none text-rebecca-purple"
    viewBox="0 0 20 20"
    fill="currentColor"
    aria-hidden="true"
  >
    <path
      fillRule="evenodd"
      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
      clipRule="evenodd"
    />
  </svg>
);

export default function Subscribe() {
  const { user, paid } = useAuth();
  const subscribeBtnRef = useRef(null);

  const nav = useNavigate();

  const planFeatures = [
    "AI-curated weekly Chart Show",
    "Advanced AI powered production",
    "Fully automated workflow",
    "Interactive listener engagement",
  ];

  const handlePayment = () => {
    subscribeBtnRef.current.innerText = "Fetching Subscription..";
    subscribeBtnRef.current.disabled = true;
    if (!auth.currentUser) {
      nav("/login");
      return;
    }
    fetch(`${backendURL}/payment`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ userId: user.uid }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.url) window.location.href = data.url;
        subscribeBtnRef.current.innerText = "Subscribe Now";
        subscribeBtnRef.current.disabled = false;
      })
      .catch((error) => {
        console.error(error);
        subscribeBtnRef.current.innerText = "Subscribe Now";
        subscribeBtnRef.current.disabled = false;
      });
  };
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            The world’s first chart show produced & presented by AI
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            AI Top 40 is the most advanced AI-driven radio show in the world. AI
            leads every stage of the production process including tracking
            competitors, compiling the chart, creating the content, presenting
            the show and playing it out.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto text-center md:text-left">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">
              LICENSE FOR YOUR STATION
            </h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Bring the best chart show for your station with AI Top40!
              AI-curated list of the hottest songs for your audience!
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-rebecca-purple">
                What’s included
              </h4>
              <div className="h-px flex-auto bg-gray-100"></div>
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {planFeatures.map((featureText) => {
                return (
                  <li key={featureText} className="flex gap-x-3">
                    {checkMarkSvg}
                    {featureText}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">
                  Pay weekly, enjoy nonstop music.
                </p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">
                    $150
                  </span>
                  <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                    USD
                  </span>
                </p>
                <button
                  ref={subscribeBtnRef}
                  onClick={handlePayment}
                  className="mt-10 block w-full rounded-md bg-rebecca-purple disabled:bg-purple-900 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-rebecca-dark-purple"
                >
                  Subscribe Now
                </button>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                  Invoices and receipts available for easy company reimbursement
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
